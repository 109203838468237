export default {
    data() {
        return {
            swe: {
                //Cashier + CashierOptions
                cashier_cashierTitle: "Kassör",
                cashier_managerMenuTitle: "Managermeny",
                cashier_promotionsTitle: "Kampanjer",
                cashier_cashierOptionsTitle: "Kassaalternativ",
                cashier_managerOptionsTitle: "Manageralternativ",
                sign_In: "Logga in",
                sign_Out: "Logga ut",
                cashierAmount: "Kassabelopp",
                lblBlocked: "Blockerad",
                btnBlockCashier: "Blockera kassa",
                btnUnblockCashier: "Avblockera kassan",
                btnCashierCheck: "Kassacheck",
                btnAccInfo: "Biljettinformation",
                btnCloseAcc: "Stäng konto",
                btnCloseAccs: "Stäng konton",
                btnRegisterFill: "Registrera Fyll",
                btnRegisterDrop: "Registrera Drop",
                btnTerminalStatus: "Terminalstatus",
                btnReports: "Rapporter",
                btnPaidAccounts: "Betalkonton",
                btnInformesGistra: "GISTRA Rapport",
                btnNavision: "Navision",
                btnAddCredits: "Lägg till krediter",
                btnNewPin: "Skapa konto",
                drawerAmount: "SÄTTA IN URSPRUNGLIG KASSAMONTERING",
                drawerFinalAmount: "SÄTTA IN SLUTLIG KASSAMONTERING",
                drawerFillCashier: "SÄTTA IN FYLLNINGSKASSEN",
                drawerDropCashier: "SÄTT IN DROPPE KASSA MOUNT",
                drawnerBtnNewPin: "SÄTTA NY PIN BELÖP",
                drawnerCashierCheck: "SÄTT I KASSA KONTROLLBELOPP",
                btnEdit: "Redigera",
                btnDelete: "Ta bort",
                btnView: "Visa",
                btnClose: "Stäng",
                btnOk: "Ok",
                btnTest: "Testa",
                btnAdd: "Lägg till",
                btnReturn: "återvända",
                btnBack: "Tillbaka",
                btnCancel: "Avbryt",
                btnLogin: "Logga in",
                btnYes: "Ja",
                btnNo: "Nej",
                btnExit: "Avsluta",
                btnRefresh: "Uppdatera",
                btnReset: "Återställ",
                btnWait: "Vänta...",
                btnSave: "Spara",
                btnConfirm: "Bekräfta",
                btnDiscard: "Kassera",
                btnApply: "Tillämpa",
                btnReprintTicket: "Tryck biljett igen",
                lblPassword: "Lösenord",
                lblConfirmPassword: "Bekräfta lösenord",
                lblValue: "Värde",
                lblNewValue: "Nytt värde",
                lblReserveValue: "Återställ reserv",
                waiting: "väntar",
                checkingLicense: "Kontrollerar licens",
                cmdStartCashier: "Starta kassa",
                cmdFinshCashier: "Avsluta kassa",
                cmdCashierBlock: "Kassablock",
                cmdCashierUnblock: "Kassör avblockera",
                cmdAccountInfo: "Kassakontoinformation",
                cmdCloseAccounts: "Kassa stäng konton",
                cmdCloseAccountsOK: "Stängningsbiljett",
                cmdCloseAccountsCancel: "Avbryt biljett",
                cmdCashierRegisterFill: "Kassaregister Fyll",
                cmdCashierRegisterDrop: "Släpp kassaregister",
                cmdCashierAddNewPin: "Kassa Lägg till ny pin",
                messageSignOutCashier: "Är du säker på att du vill stänga Cashier?",
                messageBlockCashier: "Är du säker på att du vill blockera Cashier?",
                messageUnblockCashier: "Är du säker på att du vill avblockera kassa?",
                messageErroBlockUnblockCashier: "Endast Cashier-användaren kan blockera eller avblockera Cashier",
                ticketTitle: "Biljett - Ange biljettnummer",
                authenticationTitle: "Autentisering",
                columnTicket: "Biljett",
                lblSearchAccount: "Sök efter konto",
                reprintAccount_Title: "Skriv ut konto igen",
                btnReprint: "Omtryck",
                messageSessionClose: "Sessionen är inte öppen...",
                lblWarning: "Varning",
                cashier_Data: "Kassadata",
                columnStartCash: "Starta Cash",
                columnTotalFill: "Totalt fyllning",
                columnTotalDrop: "Totalt fall",
                columnTotalAccountIn: "Totalt konto in",
                columnTotalAccountOn: "Totalt konto ute",  
                columnTotalCompAccount: "Totalt kompletterande konto",
                columnFinalCash: "Slutliga kontanter",
                columnData: "Data",
                messageSignOutPart1: "Endast användare[",
                messageSignOutPart2: "] kan göra utloggningen",

                //ManagerOptions
                btnUnblockMachines: "Avblockera maskiner",
                btnBlockMachines: "Blockera maskiner",
                btnAutoBlockMachines: "Automaskiner",
                messageBlockMachines: "Vill du verkligen blockera maskiner?",
                messageUnblockMachines: "Vill du verkligen avblockera maskiner?",
                btnCloseSession: "Stäng session",
                btnOpenSession: "Öppen session",
                messageOpenSession: "Vill du verkligen öppna sessionen?",
                messageCloseSession: "Vill du verkligen stänga sessionen?",
                messageCloseSessionAlert: "Klicka på 'Skriv ut rapport'\n" +
                    "knapp för att skriva ut sessionsrapport",

                messageOpenSessionAlert: "Om den senaste sessionsrapporten inte var\n" +
                    "utskrivet, gör den här åtgärden\n" +
                    "orsaka att denna data går förlorad och kommer\n" +
                    "inte kunna reprint senare\n\n\n\n" +
                    "Vill du fortsätta utan utskrift?",
                btnCopyReport: "Kopiera rapport",
                btnPartialReport: "Delrapport",
                btnGenerateReport: "Generera rapport",
                btnPrintReport: "Skriv ut rapport",
                messageCashierOpen: "Kassasessionen är öppen, vänligen logga ut från sessionen!",

                //Header
                messageHasEmailToSend: "Det finns filer som ska skickas av e-postavsändaren",
                massageRestartServer: "Vill du verkligen starta om Megapot Server?",
                cmdRestartServer: "Starta om server",
                messageCloseServer: "Vill du verkligen stänga Megapot Server?",
                cmdServerStop: "Stäng applikation",
                messageSignOut: "Vill du verkligen logga ut?",
                cmdBlockCommunication: "Blockera kommunikation",
                messageBlockCommunication: "Vill du verkligen blockera kommunikation?",
                cmdUnblockCommunication: "Avblockera kommunikation",
                messageUnblockCommunication: "Vill du verkligen avblockera kommunikation?",
                messageAllMachinesConnect: "Alla maskiner kommer att ansluta från servern.",
                messageAllMachinesDisconnect: "Alla maskiner kommer att kopplas från servern.",
                changePassword: "Ändra lösenord",
                btnAddCash: "Lägg till kontanter",
                btnLock: "Låsmeny",
                btnResetClient: "Återställ klient",
                btnCloseClient: "Stäng klient",
                language: "Språk",
                messageTurnOffServer: "Denna åtgärd kommer att stänga av servern.",
                languageName: "Svenska",

                //Settings
                btnLogoff: "Logga ut",
                btnForcedLogOff: "Tvingad utloggning",
                btnRestart: "Starta om",
                btnShutdown: "Avstängning",
                messageProcessLogOff: "Vill du verkligen logga ut?",
                messageProcessForcedLogOff: "Vill du verkligen tvinga fram utloggning?",
                messageProcessRestart: "Vill du verkligen starta om?",
                messageProcessShutdown: "Vill du verkligen stänga av?",

                //Langauge
                messageSetLanguage: "Ändra språk...",

                //Login
                messageErrorLogin: "Fel inloggning eller lösenord",
                messageErrorPassword: "Felaktigt lösenord!",
                messageErrorByPermission: "Användaren har inte behörighet",

                //Reset Value
                messageErrorValue: "Värdet måste vara från",
                messageErrorReserveValue: "Reservvärdet måste vara från",
                messageErrorGrandValue: "Värde [Grand] måste vara från",
                messageErrorMinorValue: "Värde [Minor] måste vara från",
                messageErrorMajorValue: "Värde [Major] måste vara från",
                to: "till",
                cmdResetBonus: "Återställer värden",
                columnChange: "Ändra",
                lblNotChanged: "Inte ändrad",
                confirmReset: "Ställ in nya värden",
                messageConfirm: "Är du säker?",
                lblValueWillBe: "Värde kommer att vara",
                lblResetType: "Återställ typ",
                lblPayNoConnection: "Betalning utan kommunikation" ,
                lblAdjustmentSystem: "Justering på grund av systemfel",
                lblPaidValue: "Betalt värde",
                messageWarningResetValuePartOne: "Ved å velge dette alternativet vil en ny betaling på ",
                messageWarningResetValuePartTwo: " bli registrert. Dette vil føre til at den siste betalingen blir erstattet med denne verdien.",    

                //Terminals
                terminalsTitle: "Terminaler",
                terminalInfoTitle: "Terminalinformation",
                lblCounter_Title: "Räknare",
                lblGameData: "Speldata",
                lblTotalTerminals: "Totalt terminaler",
                lblLoggedTerminals: "Loggade terminaler",
                lblFinishedLogin: "Slutad inloggning",
                lblGamesAvailable: "Spel tillgängliga",
                lblInLogin: "I inloggningsprocess",

                //columnTerminals:
                columnTerminalNumber: "Terminalnummer",
                columnRealMachNum: "Verkligt Maskinnummer",
                columnTerminalIP: "Terminal IP",
                columnLocalization: "Lokalisering",
                columnGameName: "Spelnamn",
                columnGameGroup: "Spelgrupp",
                columnGameNumber: "Spelnummer (senaste spelning)",
                columnProgressiveGroup: "Progressiv grupp",
                columnMoney: "Pengar",
                columnSoftwareVersion: "Programversion",
                columnCredit: "Kreditera",
                columnTotalCoinIn: "Totalt mynt in",
                columnTotalCoinOut: "Totalt mynt Out",
                columnTotalGamesPlayed: "Totalt spelade spel",
                columnTotalCashIn: "Totala Cash In",
                columnTotalCashOut: "Totala utbetalning",
                columnTotalHandPays: "Totala HandPays",

                //CopyReport:
                availableFiles: "Tillgängliga filer",
                addFiles: "Ladda filer som ska hanteras",
                messageNoFiles: "Inga filer hittades",
                messageLargeFiles1: "Vald filstorlek: ",
                messageLargeFiles2: "Filer som är större än 500 MB kan ta ett tag att kopiera",
                messageLargeFiles3: "Obs: Filer kommer att komprimeras innan kopiering",
                btnViewErrors: "Visa fel",
                btnResend: "Skicka igen",
                btnCopyFile: "Kopiera fil",

                //Email
                emails: "E-posts",
                email: "E-post",
                btnAccountConfig: "Kontokonfiguration",
                btnSendFilesNow: "Skicka filer nu",
                btnSizeConfig: "Filstorlek Config",
                messageSetMaxFileSize: "Spara max filstorlek",
                lblSearchEmail: "Sök med e-post...",
                account: "Konto",
                SMTPServer: "SMTP-server",
                SMTPAuthenticate: "SMTP Authenticate",
                SMTPPort: "SMTP-port",
                useStartTLS: "Använd Start TLS (SSL)",
                SMTPTimeout: "SMTP-autentisering",
                btnTestSend: "Testsändning",
                tabOthers: "Andra",
                tabRetry: "Försök igen",
                automaticEmailSender: "Automatisk e-postavsändare",
                mobileInternet: "Mobilt Internet",
                serialPortName: "Serieportens namn",
                commDialUpName: "Comm Uppringt namn",
                firstRetry: "Första försöksintervall",
                secondRetry: "Andra försöksintervallet",
                thirdRetry: "Tredje försöksintervallet",
                subsequentRetry: "Efterföljande försöksintervall",
                delayNotification: "Fördröjning av meddelande",
                expirationTimeout: "Tidsgräns för utgång",
                reportListTitle: "Rapportlista",
                emailConfigTitle: "E-postkonfiguration",
                msgErrorEmail: "E-post inte konfigurerad",
                messageSendManualEmail: "Skickar e-post...",
                messageTestEmail: "Testa e-post...",

                //Colums name
                columnName: "Namn",
                columnEmail: "E-post",
                columnActions: "Handlingar",
                columnField: "Fält",
                columnValue: "Värde",
                columnPlatform: "Plattform",
                columnTimeToRestart: "Tid för omstart (minuter)",

                //Time
                hours: "Timmar",
                minutes: "Minuter",
                seconds: "Sekunder",
                days: "Dagar",

                //Panels
                panelsTitle: "Paneler",
                lblSearchPanels: "Sök efter panel...",
                lblSearchFooter: "Sök med sidfot...",
                columnText: "Text",
                btnDisplayName: "Displaypanelens namn",
                messageDisplayName1: "Denna åtgärd kommer att visa panelens namn",
                messageDisplayName2: "Är du säker på att du vill fortsätta?",
                messageDisplayNameCMD: "Sänder visningsnamn på varje panel...",
                messageDisplayNameFalse1: "Ett meddelande med panelnamnet visas för närvarande i <b>Alla</b> paneler.",
                messageDisplayNameFalse2: "<b>Om du vill avsluta visningen, trycker du på knappen \"Avbryt visning\"",
                btnCancelDisplay: "Avbryt visning",
                btnSetGroups: "Ange grupper",
                btnSetFooterPlaylist: "Ställ in footer-spellista",
                btnSetPlaylist: "Ställ in spellista",
                btnDeletePanel:"Ta bort panel",
                processDeletePanelPart1: "Är du säker på att du vill <b>ta bort</b> din panel <b>(",
                processDeletePanelPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageDeletePanel: "Tar bort panel...",
                processDeletePanelTitle: "Tar bort panel",
                btnRestartPanel: "Starta om panelen",
                processRestartPanelPart1: "Är du säker på att du vill <b>Starta om</b> din panel <b>(",
                processRestartPanelPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageRestartPanel: "Återställer panelen...",
                processRestartPanelTitle: "Återställer panel",
                btnShutdownPanel:"Avstängningspanel",
                processShutdownPanelPart1: "Är du säker på att du vill <b>Stänga av</b> din panel <b>(",
                processShutdownPanelPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageShutdownPanel: "Stänger av panelen...",
                processShutdownPanelTitle: "Stänger av panelen",
                btnResetFactoryPanel: "Återställ fabrikspanel",
                processResetFactoryPanelPart1: "Är du säker på att du vill <b>Återställa fabriksinställningarna </b> din panel <b>(",
                processResetFactoryPanelPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageResetFactoryPanel: "Vilopanel...",
                processResetFactoryPanelTitle: "Vilopanel",

                //Coluns name
                columnVersion: "Version",
                columnPanel: "Panel",
                columnCommVersion: "CommVersion",
                columnStatus: "Status",
                lblConnected: "Ansluten",
                lblDisconnected: "Frånkopplad",

                //Progressive
                lblProgressive: "Progressiv",
                lblProgressiveDNG: "LAP",
                multiProgressives_Title: "MultiProgressiv",
                multiProgressive_Title: "MultiProgressiv",
                progressive_TabName: "Progressiv data",
                machines_Title: "Maskiner",
                lblSearchIPorMachNum: "Sök med IP eller MachNum...",
                btnResetProgressive: "Återställ progressiv",
                btnShowProgressive: "Visa progressiv",
                btnDeleteTerminal: "Ta bort terminal",
                btnPaymentTerminal: "Betalningsterminal",
                btnOpenMonitor: "Öppna bildskärm",
                lblSearchProgressive: "Sök efter progressiv",
                lblSearchGames: "Sök efter spel",
                lblSearchStatus: "Sök efter status",
                lblSearchTerminalInNone: "Terminaler i NONE",
                lblSearchAllProgressive: "Alla progressiva",
                lblSearchMultiProgressive: "Sök med MultiProgressive",
                lblSearchAllInThisProgressive: "Allt i denna progressiva",
                lblTypeProgressive: "Skriv progressiv",
                btnMoveProgressive: "Flytta terminal till progressiv",
                switchSelectAll: "Välj alla",
                messageSetTerminalProgressive: "Moving Progressiv(s) ...",
                messagePaymentTerminal: "Behandlar terminal(er) betalning...",
                messageOpenTerminal: "Sänder öppna terminal(er) ...",
                messageDeleteTerminal: "Tar bort terminal(er) ...",
                messageNoTerminalsAvailable: "Inga terminaler tillgängliga",
                messageBtnOkMoveTermnalToProgressive: "Välj en terminal för att släppa knappen",
                messageBtnShowTerminalOptions: "Terminalalternativ",
                messageBtnShowClientOptions: "Klientalternativ",
                massageConfigTerminalOptionPayment: "Vill du betala för den här maskinen?",
                massageConfigTerminalOptionOpen: "Vill du öppna inställningarna för den här maskinen?",
                massageConfigTerminalOptionDelete: "Vill du ta bort den här maskinen?",
                lblSelectOption: "Välj ett alternativ",
                lblSelectOperator: "Välj ett Operator",
                lblGroupMachines: "Gruppmaskiner",
                lblClosed: "Stängt",
                lblOpen: "Öppna",
                fractioned_Title: "Fraktionerad",
                lblTableEnginesDataProg: "Motorer i denna progressiva",
                columnTotalBet: "Total insats",
                columnPercentage: "Procent",
                btnBlockClient: "Blockera klient",
                btnUnblockClient: "Avblockera klient",
                msgBlockClientConfirmation: "Vill du blockera den här klienten?",
                msgUnblockClientConfirmation: "Vill du avblockera den här klienten?",
                lblWarnings: "Varningar",
                msgWarnings: "Det finns varningar från servern, klicka här för att se dem",
                msgDeleteClientConfirmation: "Vill du ta bort den här klienten?",
                msgRecoverClientConfirmation: "Vill du återställa den här klienten?",
                btnDeleteClient: "Ta bort klient",
                btnRecoverClient: "Återställ klient",

                //columnNameProg
                columnProgressiveType: "Progressiv typ",
                columnProgressiveConfig: "Progressiv konfiguration",
                columnConfig: "Konfigurera",   
                columnCoinValue: "Myntvärde",
                columnPlayJackpotSoundOn: "Spela Jackpot Sound On",
                columnContribution: "Bidrag",
                columnLevel: "Nivå",
                columnIP: "IP",
                columnInitialValue: "Ursprungligt värde",
                columnMaxValue: "Max värde",
                columnMinBetToPartipate: "Min bet för att delta",
                columnMaxProportionalBet: "Max proportionell insats",
                columnEnableIncSound: "Spela inkrementera ljud",
                columnIncrementSoundValue: "Öka ljudvärde",
                columnReserveEnabled: "Reservbidrag Aktiverat",
                columnReservePerc: "Reserv i Procent",
                columnReserveValue: "Reservvärde",
                columnReserveIni: "Reservera Initialt Värde",
                columnReserveMax: "Reserv Maxvärde",
                columnMidasFortuneBonus: "Midas Fortune Bonus",

                //columnNameMultiProg
                columnMultiProgressiveSettings: "Multiprogressiv inställningar",
                columnMultiProgressiveType: "Multiprogressiv typ",
                columnMultiProgressiveConfig: "Multiprogressiv Config",
                columnTotalContribution: "Totalt bidrag",
                settings: "Inställningar",
                

                //Users
                users_Title: "Användare",
                user_Title: "Användare",
                user_tabName: "Användardata",
                report_tabName: "Rapportdata",
                btnAddUser: "Lägg till användare",
                btnAddReportLevel: "Lägg till rapportnivå",
                lblSearchByLogin: "Sök med inloggning...",
                lblSearchByName: "Sök på namn...",
                messageDeleteUser: "Tar bort användare...",
                processDeleteUserTitle: "Raderar konto...",
                processDeleteUserPart1: "Är du säker på att du vill <b>ta bort</b> ditt konto <b>(",
                processDeleteUserPart2: ")</b>? Denna åtgärd kan inte ångras.",

                messageDeleteReportLevel: "Tar bort reportLevel...",
                rocessDeleteReportTitle: "Tar bort ReportLevel",
                processDeleteReportPart1: "Är du säker på att du vill <b>ta bort</b> din ReportLevel <b>(",
                processDeleteReportPart2: ")</b>? Denna åtgärd kan inte ångras.",

                //Column User
                columnLastUpdated: "Senast uppdaterad",
                columnFunction: "Fungera",
                columnLogged: "Loggad",
                columnNotLogged: "Inte loggad",

                //User Manager
                userManager_Title: "Användarhanterare",
                usersManagement: "Hantering av användare",
                cashier_Option: "Kassa",
                technician_Option: "Tekniker",
                reportView_Option: "Rapportvy",
                manager_Option: "Chef",
                root_Option: "Rot",
                lblSelectALevel: "Välj en nivå",
                messageErrorInvalidName: "Ogiltigt namn. Endast bokstäver och siffror är tillåtna.",
                messageUserExist1: "Logga in [",
                messageUserExist2: "] finns redan",
                messageErrorOptions: "Vänligen markera minst ett alternativ",
                messageErrorOperator: "Velg minst én operatør",
                processCreateUser: "Skapar användare...",
                processEditUser: "Redigerar användare...",
                lblMultipleFunctions: "Flera funktioner",
                lblSessionMangerFunction: "Sessionshanterare",
                lblSearchOrAddTag: "Sök eller lägg till en tagg",
                lblAddThisTag: "Lägg till detta som ny tagg",
                lblSearchOrAddOperator: "Sök eller lägga till en operator",
                lblAddThisOperator: "Lägg till detta som ny operatör",
                lblSelectALabel: "Tryck på enter för att välja",
                lblDeselectLabel: "Tryck på enter för att ta bort",
                messageReLogin: "Klicka på Ok för att logga in med det nya lösenordet",
                messageChangePassword: "Vänligen uppdatera ditt lösenord!",

                //Report Level Manager
                reportLevelManager_Title: "Konfigurationer för rapportåtkomst",
                errorMessageInvalidReport: "Välj minst en rapport.",
                messageCreateReport: "Sparar rapportnivå...",
                messageEditReport: "Redigerar rapportnivå...",

                //Configurations
                configurations_Title: "Konfigurationer",
                deckDatabase_Title: "Databas",
                deckEstablishment_Title: "Serverinformation",
                deckResetSettings_Title: "Alternativ",
                btnLicense: "Serverlicens",
                btnCopyLogs: "Kopiera loggar",
                btnCopyCert: "Kopiera certifikat",
                btnLoadSettings: "Ladda inställningar",
                btnBackup: "Säkerhetskopiering",
                btnRestore: "Återställ",
                stepLoadFile: "Ladda fil",
                stepCredentials: "Inloggningsuppgifter",
                lblTechnicianLogin: "Tekniker inloggning",
                lblExecutionCode: "Utförandekod",
                lblDatabasePassword: "Databaslösenord",
                errorMessagePasswordTooShort: "Lösenordet är för kort",
                errorMessagePasswordAtLeast: "Lösenord måste ha minst 8 tecken",
                stepInformation: "Information",
                stepFinish: "Slutför",
                messageDownloadConfig: "Hämtar konfiguration, vänta...",
                messageUploadConfig: "Ladar upp konfiguration, vänta...",
                messageUploadDatabase: "Lader upp databas, vänta...",
                errorMessageInvalidExecutionCode: "Ogiltig exekveringskod",
                errorMessageInvalidLoginWithExecutionCode: "Ogiltig exekveringskod, användarnamn eller lösenord",
                errorMessageInvalidLogin: "Ogiltigt användarnamn eller lösenord",
                messageInstallingConfiguration: "Installerar konfigurationer, vänta...",
                messageRequestConfig: "Begär inställningar...",
                messageRequestFiles: "Begär filer...",
                messageRequestPenDriveList: "Begär PenDrive-lista",
                messageDoBackupCMD: "Säkerhetskopierar loggar och databas...",
                btnResetServerConfiguration: "Återställ Serverkonfiguration",
                btnResetGlobalConfiguration: "Återställ Megapot konfiguration",
                btnResetClientConfiguration: "Återställ Klientkonfiguration",
                messageResetClient: "Vill du verkligen starta om klientkonfigurationen?",
                btnResetBothConfiguration: "Återställ server- och klientkonfiguration",
                btnResetS2SConfiguration: "Återställ S2S-inställningar",
                btnResetBigOsAddress: "Återställ BigOS Address",
                btnResetGlobalClient: "Återställ Megapot adress",
                btnManageSystem: "Hantera system",
                messageRunOsAdmin: "Laddar OS-administrationsskärm...",
                messageHasEmailSenderPart1: "Det finns en tidigare konfiguration av e-postavsändare.",
                messageHasEmailSenderPart2: "Vill du behålla den?",
                messageHasEmailSenderPart3: "(Obs.: Konfigurationen av e-postmeddelandet, om de behålls kan rapporterna skickas till Reportal).",
                messageDiscardEmailSender: "Vänta, tar bort e-postavsändare...",
                messageGenerateLicenseCMD: "Generating Requisition...",		
                messageGenerateLicenseAutomaticCMD: "Requesting License...",		
                msgCheckAutoLicense: "Du har konfigurerat en BigOS-adress och kan skapa din licens automatiskt. Vill du fortsätta? Om du väljer \"NEJ\" måste du gå igenom stegen manuellt",
                msgCancelLicenseReq: "Generate new license requisition",		
                msgCancelLicenseQuest: "Generating a new license requisition will invalidate any previously opened requisition. Do you want do continue?",		
                msgGenNewLicense: "This action will generate a new license requisition, which doesn't cancel the current license, if you have a working one. Do you want do continue?",
                msgDeleteDatabase: "Den här åtgärden tar bort databasen. Vill du fortsätta?",
                msgDeletingDatabase: "Ta bort databas...",

                //Session
                stepChooseReport: "Välj rapport",
                messageChooseReport: "Välj typrapport",
                btnCurrent: "Aktuell",
                btnBySession: "Per session",
                stepReportType: "Rapporttyp",
                messageReportType: "En ny rapport kommer att genereras Välj vilken typ av rapport du vill generera.",
                btnFinal: "Slutlig",
                btnPartial: "Partiell",
                stepGenerateCSV: "Generera CSV",
                messageGenerateCSV: "Generera CSV efter session eller datum",
                btnGenerate: "Generera",
                messageReportTypeFinal: "Vänta medan servern genererar slutrapporter...",
                messageReportTypePartial: "Vänta medan servern genererar delrapporter...",
                messageInformationBySession: "Vänta medan servern genererar rapporter efter vald session...",
                messageGenerateCSVRequest: "Genererar GameReport CSV...",
                btnManualCashierSignOut: "Manuell kassalogga ut",
                lblSearchByTerminal: "Sök på terminal...",
                session_Title: "Session",
                lblStartedAt: "Började på",
                lblClosedAt: "Stängt kl",
                lblType: "Typ",
                lblLastSessionClose: "Sista session avslutas",
                lblSessionControl: "Sessionskontroll",
                lblSessionControlManual: "Manuell",
                lblSessionControlAutomatic: "Automatisk",
                lblSessionControlSemiAutomatic: "Halvautomatisk",
                lblSearchCashier: "Sök efter kassa...",

                //Footer
                serverVersion: "Serverversion",
                sessionStatus: "Sessionsstatus",

                //Token
                token_Title: "Tecken",
                token_messageInfo: "Informera lösenordet nedan till centralen för att fortsätta",
                token_messageError1: "Felaktigt lösenord! du har ",
                token_messageError2: " fler försök",

                //Auth
                messageAuthReportManager: "För att komma åt rapportområdet, logga in som en användare aktiverad för modulen.",
                messageErrorNotSet: "är inte inställd för",
                messageLoggedIn: "inloggad tillfälligt",

                //ReportManager
                reportManager_Title: "Rapporter",

                //Bonus
                bonus_Title: "Bonus",
                bonusData_tabName: "Bonusdata",
                engineData_tabName: "Engines Data",
                lblResetBonus: "Återställ bonus",
                lblTableEnginesData: "Motorer i denna bonus",

                //ColumnBonus
                columnMrChampionConfig: "Mr Champion Konfig",
				columnMannyBucksConfig: "Manny Bucks Konfig",
                columnMinPlayersMystery: "Min spelare till Mystery",
                columnPigBoomConfig: "Pig Boom! Konfig",
                columnMinPlayersBonus: "Min spelare till bonus",
                columnPrizeValue1: "Prisvärde 1",
                columnPrizeValue2: "Prisvärde 2",
                columnPrizeValue3: "Prisvärde 3",
                columnPrizeMode: "Prisläge",

                //IniFront
                iniFront_Title: "Klientkonfiguration",
                lblPreferencialIP: "Företrädesrätt IP",
                messageErrorIP: "Ange en giltig adress",
                messageCreatedIniFile: "Skapade iniFile, vänta med att starta om...",
                messageNoConnection: "Ingen anslutning till IP",
                iniFile_Title: "Ini-fil",
                messageNeedsIPConfig: "FBM Server behöver dessa konfigurationer för att köras, vänligen konfigurera för att använda.",
                lblValidatinIP: "Validerar IP...",
                lblErrorDeleteFile: "Fel i radera klientfil",
                messageSaveBigOsSettings: "Spara BigOS-inställningar, vänta...",

                //IniServer
                iniServer_Title: "Serverkonfigurationer",
                lblCountryCode: "Landskod",
                messageErrorCountryCode: "Välj ett land",
                lblDatabaseIP: "Databas IP",
                fbmServer_Option: "Kör Megapot Server vid Windows-start",
                spMaster_Option: "Kör SP-Master vid uppstart av Windows",
                fbmServerWillRestart: "Megapot Server kommer att starta om...",
                dingoServer_Option: "Kör DINGO-Server vid Windows-start",
                dingoServerWillRestart: "DINGO-Server kommer att starta om...",
                lblFillAlesis: "Vänligen fyll i Alesis inställningar",
                btnCheckTime: "Kontrollintervall (minuter)",
                btnBigosAddress: "BigOS Adress",
                checkAutomatic: "Kontrollera automatiskt efter nya konfigurationer",
                checkAutomaticTerminal: "Skicka terminalstatus",
                messageConfiguratorSettingsPart1: "Observera att webbadressen kan ändras beroende på vilken miljö som används.",
                messageConfiguratorSettingsPart2: "Om du använder SystemConfigurator måste du avsluta adressen med SystemConfigurator, till exempel https://example.com/SystemConfigurator.",
                messageConfiguratorSettingsPart3: "Om du använder BigOS utan https måste du informera 9000-porten, till exempel http://example.com:9000, om du använder https avsluta adressen med backend, exempel https://example.com/backend .",
                messageConfiguratorSettingsPart4: "Den första delen av adressen http(s):// krävs i alla scenarier",

                //GlobalForm
                global_Title: "Megapot inställningar",
                isGlobal_Title: "Megapot inställningar",
                isGlobalProgressive: "Använd Megapot",
                globalProgressiveMode: "Megapot läge",
                clientOnly_Option: "Endast klient",
                serverOnly_Option: "Endast server",
                clientAndServer_Option: "Klient och server",
                lblGlobalProgressiveServerIP: "Megapot Server IP",
                globalPreferencialIP_Option: "Megapot Preferencial IP-anslutning",
                anotherLocalIP_Option: "Använd en annan lokal IP för att ansluta",

                //GistraForm
                gistra_Title: "Gistra-inställningar",
                lblServerIP: "Server IP",
                messageErrorSelectLogin: "Välj inloggning",
                lblPort: "Port",
                messageErrorPort: "Välj en port",

                //AlesisForm
                alesis_Title: "Alesis Inställningar",
                lblSeller: "Leverant ID",
                messageErrorlblSeller: "Säljarens ID är tomt",
                lblSqlUser: "SQL-användare",
                messageErrorlblSqlUser: "SQL-användaren är tom",
                lblDataBaseName: "Databasnamn",
                messageErrorlblDataBaseName: "Alesis databasnamn är tomt",
                lblSqlPassword: "SQL-lösenord",
                lblConfirmSqlPassword: "Bekräfta SQL-lösenord",
                messageErrorProvider: "Välj en leverantör",

                //BigOs
                messageErrorCheckTime: "Värdet kan inte vara 0 eller lägre",

                //App
                hideMenuMessage: "Döljer meny...",
                hideShowMessage: "Laddar meny...",

                //Promotions
                menuPromotions_Title: "Menykampanjer",
                validateCoupon_Title: "Validera kupong",
                generateCoupon_Title: "Generera kupong",
                couponManagement_Title: "Kuponghantering",
                messageGetPromotionsInfo: "Ladder kampanjinformation...",
                lblPromoNoAvailable: "Inga kampanjer tillgängliga",
                messageValidateCoupon: "Validerar streckkod...",
                messageErrorCode: "Validerar felkod...",
                confirmation_Title: "Bekräftelse",
                messageUpdateCoupon: "Bekräftar och uppdaterar kupong...",
                messageGenerateCoupon: "Genererar kuponger...",
                messageFindByVlt: "Bekräftar VLT...",
                btnRefreshMachines: "Refresh Machines",
                messageRefreshMachines: "Söker efter maskiner...",

                //ValidateCoupon
                lblCurrentPromotion: "Aktuell kampanj",
                lblStarts: "Startar",
                columnDrawn: "Ritat",
                columnOrder: "Beställa",
                columnAward: "Tilldela",
                columnCoupon: "Kupong",
                columnDate: "Datum",
                btnValidate: "Validera",

                //GenerateCoupon
                lblNoCodeAvailable: "Ingen kod tillgänglig",
                lblContactCentral: "Kontaktcentralen",
                btnSearchMachine: "Sökmaskin",
                lblMachineOutOfService: "Maskin ur drift",
                lblCriterionBet: "Kriterium + Bet",
                lblAvailableMachine: "Tillgängliga maskiner",
                lblCoupons: "Kuponger",

                //CouponManagement
                lblEnterPromotions: "Ange kampanjkoden",
                lblPromotionCode: "Kampanjkod",
                lblUserInformation: "Användarinformation",
                lblAddress: "Adress",
                lblPhone: "Telefon",
                lblIdentification: "Identifiering",
                columnCouponCode: "Kupongkod",
                columnMachine: "Maskin",
                columnPromotion: "Befordran",
                columnCriterion: "Kriterium",
                columnPrinted: "Tryckt",
                btnPrintCoupons: "Skriv ut kuponger",
                btnReprintCoupons: "Skriv ut kuponger igen",
                btnPrintTest: "Skriv ut test",
                messagePrintTestCoupon: "Skriver ut testkupong...",

                //PlayersAccount
                lblStatusFilter: "Statusfilter",
                lblCreationDateFilter: "Skapandedatum filtrerat",
                free_Option: "Gratis",
                used_Option: "Används",
                other_Option: "Annan",
                paying_Option: "Betalar",
                expired_Option: "Uppgått",
                created_Option: "Skapat",
                canceled_Option: "Avbruten",
                lblInitialDate: "Inledande datum",
                lblFinalDate: "Slutdatum",
                lblChooseADate: "Välj ett datum",
                on_Option: "På",
                lblErrorSelectDates: "Båda datumen måste väljas.",
                columnBalance: "Balans",
                columnExpires: "Upphör kl.",
                playersAccount_title: "Spelarkonto",

                lblNoDataFound: "Inga data hittades...",
                lblSearchByPin: "Sök med pin...",
                lblLimit: "Begränsning",

                //Keyboard
                btnBackspace: "backsteg",
                btnClear: "rensa",
                lblOpenKeyboard: "Öppna tangentbord",

                //Report Level
                lblFinancial: "Finansiell",
                reportSessionBalanceTot: "Sessionssaldo totalt rapport",
                reportSessionReport: "Sessionsrapport",
                reportPrize: "Pris",
                reportPayOutSummary: "Sammanfattning av utbetalningsrapport",
                reportProgressiveGroupReport: "Progressiv koncernrapport",
                lblJackpotGlobal: "Megapot",
                reportStatusReport: "Statusrapport",
                reportPrizeReport: "Prisrapport",
                lblGame: "Spel",
                reportGameListReport: "Spellista Rapport",
                reportGameGroupReport: "Spelgrupps Rapport",
                lblVouchers: "Kuponger",
                reportIssuance: "Utsläpp",
                reportRedemption: "Upprättelse",
                reportStatusPaid: "Betalt",
                reportStatusOpened: "Öppnad",
                reportStatusExpired: "Utgånget",
                reportUnclaimed: "Ohämtad",
                reportCashOut: "Uttag",
                reportStatus: "Status",
                lblTerminal: "Terminal",
                lblLiability: "Ansvar",
                reportGameTerminalReport: "Spel terminal rapport",
                reportTerminalEventReport: "Terminal händelse rapport",
                reportGameTerminalCountsReport: "Rapport för antal spelterminaler",
                reportReconcilationReport: "Avstämnings rapport",
                reportReportAccountingFinal: "Rapport Bokföring Final",
                reportRamClearHistReport: "Ram Rensa historik rapport",
                reportPlayHistReport: "Spelhistorikrapport",
                reportBillIn: "Bill In",
                lblGenReports: "Allmänna Rapporter",
                lblServerReports: "Server Rapporter",
                reportTerminalSalesReport: "Terminal försäljnings rapport",
                reportReport: "Rapportera",
                reportTerminalAuditReport: "Terminal revisions Rapport",
                reportAuditReport: "Revisions Rapport",
                reportTerminalReport: "Terminal Rapport",
                lblMystery: "Mystery",
                lblPigBoom: "Pig Boom!",
                lblMannyBucks: "Manny Bucks",
                reportMysteryPrizeReport: "Mystery Pris Rapport",
                reportMannyBucksPrizeReport: "Manny Bucks Pris Rapport",
                reportBonusPrizeReport: "Bonus Pris Rapport",

                //Report Manager
                bySession: "Efter session",
                byPeriod: "Efter period",
                byMachines: "Av Maskiner",
                current: "Aktuell",
                perUser: "per användare",
                perCashier: "per Kassa",
                lifeTime: "Livstid",
                summary: "Sammanfattning",
                hist: "Hist",
                lblExport: "Exportera",
                lblSelectType: "Välj typ",
                lblSelectDevice: "Välj en enhet",
                lblFilter: "Filtrera",
                lblSelectUser: "Välj en användare",
                lblSelectCashier: "Välj kassa",
                lblSelectMachine: "Välj maskin",
                lblCurrentSession: "Aktuell session",
                lblSelectDate: "Välj ett datum",
                lblClickToSelect: "Klicka för att välja...",
                lblSelectSession: "Välj en session",
                messageErrorGetReport: "Det gick inte att få rapport",
                checkFieldsReport: "Kontrollera fälten för Generera rapport.",
                messageExportReport: "Exporterar rapporter...",
                
                //Report Name
                ReportFinancialSessionBalanceTotal: "Session Saldo Total Rapport",
                ReportFinancialSessionBalanceParcial: "Session Balans Delrapport",
                ReportFinancialSessionAccount: "Sessionsrapport",
                ReportFinancailSessionDesglose: "Desglose sessionsrapport",
                ReportFinancailSessionOcupation: "Occupation Report",
                 ReportFinancailSessionGistra: "Gistra Sessionsrapport",
                 ReportVoucherLiability: 'Liability',
                 ReportVoucherStatus: 'Status',
                 ReportVoucherIssuance: "Emission",
                 ReportVoucherRedemption: "Inlösen",
                 ReportVoucherUnclaimed: "Ej Inlösta",
                 ReportVoucherStatusOpened: "Cash Out",
                 ReportVoucherPlaying: "Spelar",
                 ReportVoucherReport: "Rapport",
                 ReportVoucherStatusExpired: "Kupongstatus utgången rapport",
                 ReportVoucherStatusPaid: "Kupongstatus betald rapport",
                 ReportProgressiveStatus: "Statusrapport",
                 ReportProgressivePrize: "Prisrapport",
                 ReportProgressivePayOut: "Utbetalningssammanfattningsrapport",
                 ReportProgressiveGroup: "Progressive Group Report",
                 ReportCashierTerminalSales: "Terminal försäljningsrapport",
                 ReportCashierReport: "Rapportera",
                 ReportCashierTerminalAudit: "Terminal revisionsrapport",
                 ReportCashierAudit: "Revisionsrapport",
                 ReportCashierTerminalReport: "Terminal Report",
                 ReportCashierCashierAudit: "CashierAuditReport",
                 ReportTerminalGameReport: "Spelterminalrapport",
                 ReportTerminalEvent: "Terminal Event Report",
                 ReportTerminalGameCounts: "Game Terminal Counts Report",
                 ReportTerminalReconciliation: "Avstämningsrapport",
                 ReportTerminalAccountingFinal: "Report Accounting Final",
                 ReportTerminalRamClearHist: "Ram Clear Hist Report",
                 ReportTerminalPlayHist: "Play Hist Report",
                 ReportTerminalBillInHistory: "Bill in History",
                 ReportTerminalBillInSummary: "Fakta i sammanfattning",
                 ReportGameList: "Game List Report",
                 ReportGameGroup: "Spelgruppsrapport",
                 ReportJackGlobalStatus: "Statusrapport",
                 ReportJackGlobalPrize: "Prisrapport",
                 ReportBonusMysteryPrize: "Mystery Prize Report",
                 ReportBonusPigBoomPrize: "Pig Boom Prize Report",

                //License
                license_Title: "Licens",

                //Debug
                debug_Title: "Felsökning",
                pigBoom_card_Title: "PigBoom! Kontroller",
                mystery_card_Title: "Mystery Kontroller",
                multiProgressive_card_Title: "MultiProgressiv Kontroller",
                btnForcePigBoom: "Tvinga PigBoom!",
                btnForceMystery: "Tvinga Mystery",
                btnForceMultiProgressive: "Tvinga MultiProgressivev Nivå",
                btnClearForceLevelList: "Rensa kraftnivålista",
                lblAllPigBoomGroups: "Alla Pig Boom! Groups",
                lblAllMysteryGroups: "Alla mysteriegrupper",
                lblAllMannyBucksGroups: "Alla Manny Bucks Groups",
                lblAllProgressivesGroups: "Alla progressiva grupper",
                lblSelectMultiProgressive: "Välj MultiProgressiv",
                lblSelectPigBoom: "Välj PigBoom!",
                lblSelectMystery: "Välj Mystery",
                lblSelectMannyBucks: "Välj Manny Bucks",
                lblSelectLevel: "Välj en nivå",
                lblSelectProgressive: "Välj en progressiv",
                deckPigBoom_Title: "Pig Boom! Kontroller",
                deckMystery_Title: "Mystery Kontroller",
                deckMannyBucks_Title: "Manny Bucks Kontroller",
                deckMultiProgressive_Title: "MultiProgressiv Kontroller",

                //noConnection
                messageNoConnectionFront: "Förlorad kommunikation med server, försöker ny anslutning...",
                lblError: "Fel",

                //Loading
                messageServerDidNotRespond: "Server svarade inte på kommando",
                messageServerAttempts: "på 3 försök",
                messageServerRecive: "Fick svar från kommando",
                messageLimitTime: "i begränsad tid",
                messageServerFinishedCMD: "Server avslutade kommandot",
                lblSucess: "Framgång",

                //GlobalProgressive
                globalData_tabName: "Megapot data",
                lblServerInfo: "Serverinfo",
                lblGlobalServerInfo: "Megapot Server IP",
                lblNotFound: "Hittades inte",
                lblConectedServers: "Anslutna servrar",
                resetGlobalProgTitle: "Ange progressivt värde",
                globalProgressive_Title: "Megapot",
                btnShowEstablishment: "Visa operatör och server",
                messageGetOperatorServer: "Operatör och server",
                columnOperator: "Operator",
                columnServerName: "Server namn",
                columnShortName: "Kortnamn",
                columnAddress: "Adress",
                columnCity: "Av",
                columnDateUpdate: "Datouppdatering",
                messageInfoDeleteOperator: "Ved att klicka Slett, vill systemet lösa operatören från databasen och sluter att fungera",


                //Request File
                lblRequestFile: "Begär fil",
                messageRestoreBackup: "Alla dina faktiska bingodata kommer att gå förlorade och ersättas av backupdata Vill du verkligen fortsätta?",
                messageLoadConfig: "Vill du verkligen ladda den här konfigurationen?",
                btnDownload: "Ladda ner",
                btnDonwloadConfig: "Ladda ner konfiguration!",
                btnDownloadFullConfig: "Ladda ner fullständig konfiguration! detta kommer att installera om etableringsdatabasen med den senaste konfigurationen!",
                lblPenDrive: "PenDrive",
                lblUploadFile: "Ladda upp fil",
                lblSelectItems: "Välj objekt",
                messageErrorFileSize: "är större än 10 mb, välj en mindre fil.",

                //Add Cash
                btnCopy: "Kopiera",
                messageCopy: "Du kopierade precis",
                messageCopyError: "Det gick inte att kopiera texter",
                messageNewAddCasPassword: "Använd den här koden för att skapa ett nytt lösenord",
                messageResetAddCashPassword: "Vill du verkligen återställa lösenordet? Observera att du måste be systemadministratören om ett nytt lösenord",
                btnResetPassoword: "Återställ lösenord",
                messageValidateHashCode: "Validerar hashkod",
                messageAddCash: "Lägger till krediter...",
                messageSaveHashCode: "Spara hashkodfil...",
                btnResetMach: "Återställ Mach",
                btnCreateHashCode: "Skapa hashkod",
                messageRequestHashCode: "Begär hashkodfiler...",

                //Digi
                messageValidateDigi: "Validerar digi ip...",
                btnSearch: "Sök",

                //Logs
                logs_Title: "Loggar",
                screenLog_Option: "Skärmloggalternativ",
                file_Option: "Fillogg",
                data_Option: "Datalogg",
                machineLog_Title: "Maskinlogg",
                panelLog_Title: "Panellogg",
                globalLog_Title: "Megapot logg",
                clientLog_Title: "Klientlogg",
                messageShowLogs: "Visa loggar",
                messageShowLogsEdt: "Spara logginställningar...",
                savePrefs_Option: "Spara inställningar",
                lbl_DaysDeleteOldLogFiles: "Dagar radera gamla loggfiler",

                //Dialog
                dialog_Title: "Systemet",

                //Playlist
                playlistFooter_Title: "Sidfot spellista",
                playlist_Title: "Spellista",
                lblSearchByPlaylist: "Sök efter spellista...",
                btnAddPlayList: "Lägg till spellista",
                columnMessage: "Meddelande",
                processCreatePlaylist: "Skapar spellista...",
                processEditPlaylist: "Redigerar spellista...",
                processDeletePlaylistPart1: "Är du säker på att du vill <b>ta bort</b> din spellista <b>(",
                processDeletePlaylistPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageDeletePlaylist: "Tar bort spellista...",
                processDeletePlaylistTitle: "Tar bort spellista",
                lblSelectPlaylist: "Välj spellista",
                messageErrorDate: "Datum redan valt",
                messageErrorDeletePlaylist: "Fel vid användning av spellista i paneler",
                meddelandePlaylistExist1: "Spellista [",
                messagePlaylistExist2: "] existerar redan",

                footer_Title: "Sidfot",
                lblSearchByFooter: "Sök med sidfot...",
                btnAddFooter: "Lägg till sidfot",
                processCreateFooter: "Skapar sidfot...",
                processEditFooter: "Redigera sidfot...",
                processDeleteFooterPart1: "Är du säker på att du vill <b>ta bort</b> din sidfot <b>(",
                processDeleteFooterPart2: ")</b>? Denna åtgärd kan inte ångras.",
                messageDeleteFooter: "Tar bort sidfot...",
                processDeleteFooterTitle: "Tar bort sidfot...",
                messageErrorFooter: "Meddelandet finns redan!",
                messageErrorDeleteFooter: "Felmeddelande används i spellistor",
                messageErrorOption: "Välj minst ett alternativ",

                //Loading
                lblLoading: "Läser in",
                
                //TerminalStatus
                All: "Alla",
                Logging: "Loggning",
                WithCredit: "Med Kredit",
                Playing: "Spelar",
                InPayment: "I betalning",
                AdminScreen: "Administratörsskärm",
                JackpotPayment: "Jackpotbetalning",
                DoorOpen: "Genom Open",
                BillError: "Bill Feil",
                PrinterErr: "Skrivarfel",
                MysteryElegible: "Mystery Berättigad",
                Blocked: "Blockerad",
                PigBoomElegible: "PigBoom! Berättigad",
                Disconnected: "Kopplad Från",
                Connected: "Ansluten",
                FinishedLogin: "Avslutad inloggning",
                InLogin: "I inloggning",
                InFilter: "I filter",

                //Cashier Dingo
                cashier_DingoTitle: "Dingo Kassör",
                btnManagerIn: "Chef i",
                btnManagerOut: "Chef ut",
                btnCashierIn: "Kassör i",
                btnCashierOut: "Kassör ut",
                btnPower: "Kraft",
                btnCloseVoucher: "Stäng kupong",
                btnCloseVouchers: "Stäng kuponger",
                btnVoucherInfo: "Information om kuponger",
                btnVoucherViewer: "Kupongvisare",
                voucher_Title: "Alternativ för kuponger",
                register_Title: "Registrera alternativ",                
                promotions_Title: "Kampanjer Alternativ",
                manager_Title: "Alternativ för chefer",
                btnReportManager: "Rapporthanteraren",
                btnUnlock: "Lås upp",
                message_Promotions_Info_Coupon: "- Kuponghanteringsknappen kommer att finnas bredvid Kassaalternativ", 
                message_Promotions_Info_Validate: "- Knappen Validera kupong kommer att finnas bredvid Chefsalternativ",
                message_Error_Reprint: "Välj minst en kupong att skriva ut igen",
                lblEnter_Coupon_Code: "Ange kupongkoden",

                //Operator
                operator_Title: "Operator",
                messageDeleteOperator: "Är du säker på att du vill <b>ta bort</b> den här operatören?" ,                
                lblConfiguration: "Konfiguration",

                //Client
                client_Title: "Kunder",
                servers_Title: "Servrar",
                activeClients: "Aktiva Kunder",
                inactiveClients: "Inaktiva Kunder",
                deletedClients: "raderade klienter",

                //Client Type
                lblSearchByServer : "Sök efter server",
                columnServer: "Server",
                lblParameters: "Parametrar",
            }
        }
    }
}