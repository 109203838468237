<template>
  <footer class="rodape">
    <b-modal v-model="modalShowWarnings" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ translate("lblWarnings") }}
          </p>
          <button type="button" class="delete" @click="hideWarnings()" />
        </header>
        <section class="modal-card-body">
          <div class="message">
            <div class="media">
              <div class="table-wrapper has-mobile-cards" style="width: 100% !important;">
                <table class="table is-bordered is-striped" style="background-color: white; color: #363636; border: 1px solid transparent;
                  border-radius: 4px; border-collapse: separate; width: 100%;">
                  <tbody>
                    <tr v-for="line in getMessages()" v-bind:value="line" :key="line">
                      <td style="width: 100%; text-align: left; vertical-align: middle !important;">{{ line }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="col-lg-12" />
        </footer>
      </div>
    </b-modal>

    <div class="col-lg-1" style="text-align: left; font-size: 14px;">
      <span style="text-align: left; font-size: 14px;" v-if="footer.version != ''" :title="translate('serverVersion')">{{ footer.version }}</span>
    </div>
    <div class="col-lg-5" style="text-align: center; font-size: 14px;">
      <div v-if="messagesFromList != ''">
        <a href @click.prevent="showWarnings()" :title="translate('lblWarnings')">
          <div class="col-lg-12">
            <i class="icons-sm icons-exclamation-triangle" style="color: orange !important;" aria-hidden="true"></i>
            &nbsp;
            <strong style="font-size: 14px;" >{{ translate("msgWarnings") }}</strong>
          </div>
        </a>
      </div>
    </div>
    <div class="col-lg-4" style="text-align: end; font-size: 14px;">
      <i class="icons-sm icons-calendar" aria-hidden="true"></i>
      &nbsp;
      {{ systemTime==null?"":systemTime.format("dddd[,] YYYY/MM/DD") }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="icons-sm icons-clock" aria-hidden="true"></i>
      &nbsp;
      {{ systemTime==null?"":systemTime.format("HH:mm:ss") }}
    </div>
    <div
      class="col-lg-2"
      style="text-align: end; font-size: 14px;"
      v-if="userLogged != null && userLogged.Login != undefined"
    >
      <i class="icons-sm icons-user" aria-hidden="true"></i>
      &nbsp;
      {{ userLogged.Login }}
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "Rodape",
  mixins: [en, es, nor, swe],
  computed: mapState(["footer", "userLogged", "lang", "systemTime"]),
  data() {
    return {
      sessionStatusString: "",
      modalShowWarnings: false,
      messagesFromList:[],
    };
  },
  props: {
    status: String,
    sessionStatus: String,
    version: String,
    footerMessage: String,
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    getMessages() {
      if(this.footer.footerMessage != ''){
        console.log(this.footer.footerMessage)
        var listMsgAux = this.footer.footerMessage.split('|');
        listMsgAux.forEach(element => {
          if(!this.messagesFromList.includes(element))
          {
            this.messagesFromList.push(element)
          }
        });
      }
      return this.messagesFromList;
    },
    showWarnings() {
      this.modalShowWarnings = true;
    },
    hideWarnings() {
      this.modalShowWarnings = false;
      this.messagesFromList = [];
    },
  },
  mounted() {
    if (this.footer.sessionStatus == 0) {
      this.sessionStatusString = "Closed";
    } else {
      this.sessionStatusString = "Open";
    }
  },
};
</script>

<style>
.rodape {
  grid-area: rodape;
  background-color: var(--senary);
  color: #333;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;
}
.light {
  font-family: Georgia, "Times New Roman", Times, serif;
  float: right;
}

@media (max-width: 1000px) {
  #divEmpty {
    display: none !important;
  }
}
</style>