<template >
  <header class="header">

    <div style="padding-top: 3px; padding-left: 30px">
      <img class="option__image" src="../../assets/megapot_white.svg"
        style="width: 100px; height: 33px; filter: invert(100%);" />
    </div>
    <h1 class="titulo">
      <div v-if="completeLogin && hasDatabase && !errorS2S && verifyUserLogged()">
        <SearchFilter :fromDash="false" :isHeader="true" :isOperator="true" :list="listOperatorFilter"
          :selected="operatorName" @ActionAfterCMDSearchFilter="updateOperatorName" />
        <div v-if="false">
          <b-form-input list="listOperator" name="listConfig" id="listConfig" class="form-control"
            v-model="operatorName" />
          <datalist id="listOperator">
            <option v-for="operator in listOperatorFilter" v-bind:value="operator.name" :key="operator.name">
              {{ operator.name }}
            </option>
          </datalist>
        </div>
      </div>
    </h1>
    <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
          @ActionFromLoading="confirmRestart()" @ActionAfterUnblockCommunication="logOutUser()"
          @ActionAfterSignOut="signOut" @ActionAfterRestart="restart" @ActionAfterAddCashHeader="openAddCash"
          @ActionAfterMachineOperation="actionAfterMachineOperation" @ActionAfterShowLogsHeader="actionAfterShowLogs"
          @ActionAfterSelectOperator="actionAfterSelectOperator" :route="route" :JsonObject="obj">
        </LoadingCMD>
      </template>
    </b-modal>

    <div class="topnav">
      <a href @click.prevent="processShowLogs()" title="Logs"
        v-if="completeLogin && verifyUserLogged()"><i
          class="icons-white icons-1x icons-logs"></i></a>

      <a href @click.prevent="processShowConfig()" :title="translate('settings')"
        v-if="completeLogin && hasDatabase && !errorS2S && verifyUserLogged()"><i
          class="icons-white icons-1x icons-cogs"></i></a>

      <a :href="'#'" :title="!communication
        ? translate('cmdUnblockCommunication')
        : translate('cmdBlockCommunication')
        " v-show="false" v-if="hasControl && completeLogin && hasDatabase && !errorS2S && verifyUserLogged()"
        @click.prevent="processConfirmCommunication()"><i class="icons-white icons-lg1 icons-wifi"
          v-if="!communication"></i><i class="icons-white icons-lg1 icons-wifi-off" aria-hidden="true" v-else></i>
      </a>

      <a href @click.prevent="processOpenAddCash()" :title="translate('btnAddCash')" v-show="false"
      v-if="completeLogin && hasAddCash && hasDatabase && !errorS2S && verifyUserLogged()">
      <i class="icons-white icons-1x icons-dollar-sign"></i>
      </a>

      <a href @click.prevent="showInfo()" title="Info" v-if="false"><i class="icons-white icons-1x icons-info-circle"></i>
      </a>

      <a href @click.prevent="hideMenu()" :title="translate('btnLock')" v-if="showHideBtns"><i
          class="icons-white icons-1x icons-lock"></i>
      </a>

      <a href @click.prevent="processRestart()" :title="translate('btnResetClient')" v-if="showHideBtns"><i
          class="icons-white icons-1x icons-sync"></i>
      </a>

      <a href @click.prevent="processClose()" :title="translate('btnCloseClient')" v-if="showHideBtns"><i
          class="icons-white icons-1x icons-times"></i>
      </a>

      <b-modal v-model="showAuth" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <Auth @close="props.close" route="/api/configuration/controls" :showLogin="true" :showPassword="true"
            :showPin="false" :local="local" @AfterAuthHeader="afterAuth" />
        </template>
      </b-modal>
      <b-modal v-model="showModalLogs" width="300px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <Logs @close="props.close" :logs="logs" />
        </template>
      </b-modal>
      <b-modal v-model="showConfig" width="900px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <Configuration @close="props.close" :IsFromHeader="true" />
        </template>
      </b-modal>
      <b-modal v-model="showChangeLanguage" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <Language @close="props.close" />
        </template>
      </b-modal>
      <b-modal v-model="showUserSettings" width="1000px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <User @close="props.close" />
        </template>
      </b-modal>
      <b-modal v-model="showAddCash" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <AddCash @close="props.close" :penDriveList="penDriveList" @OpenAddCash="processOpenAddCash"
            @RefreshPenDriveFilesAddCash="refreshPenDriveFilesAddCash" @RefreshResetPassword="refreshResetPassword" />
        </template>
      </b-modal>
      <b-modal v-model="showToken" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <Token @close="props.close" route="/api/configuration/controls" :showToken="true" :showPassword="true"
            :showPin="false" :Action="afterToken" local="Cashier/ManagerOptions" />
        </template>
      </b-modal>
      <b-modal v-model="showUser" width="640px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <UserSettings @close="props.close" :user="userLogged" :mode="mode" :isFromHeader="true"></UserSettings>
        </template>
      </b-modal>
      <b-modal v-model="showModalRestart" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <Message type="is-success" :message="message" :messageBtn="translate('btnWait')" :isList="true" />
      </b-modal>
    </div>

    <div class="row">
      <div v-if="false">
        <UserDropdown v-if="completeLogin && hasDatabase && !errorS2S" @ActionFromUserDrop="actionFromUserDrop" />
      </div>
      <div v-if="false">
        <Settings @ActionAfterSettings="afterSettings" v-if="showSettings()" />
      </div>
      <div v-if="completeLogin">
        <div style="text-align: end">
          <b-dropdown v-if="userLogged != undefined && userLogged != null" id="dropdownUsr" position="is-bottom-left"
            aria-role="list" v-model="selectedOptionUserDropdown" :title="translate('user_Title')">
            <template #trigger>
              <div class="topnav">
                <a @click.prevent="resetClassDiv()" id="btnControl" class="btnControl">
                  <i class="icons-white icons-1x icons-user"></i>
                </a>
              </div>
            </template>

            <span style="padding-left: 15px; color: var(--quinary)"><i
                style="color: var(--iconColor); margin-right: 5px; margin-bottom: 10px; min-width: 20px; text-align: center;"
                class="icons-1x icons-user" />

              {{ userLogged.Login }}</span>

            <b-dropdown-item id="opt1" aria-role="listitem" v-if="!hasControl">
              <div class="media">
                <div class="media-content">
                  <i style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                    class="icons-1x icons-account-lock-open" />{{ translate("sign_In") }}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item id="opt1" aria-role="listitem" v-else :value="'signOut'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
                      class="icons-1x icons-account-lock" />
                    {{ translate("sign_Out") }}</a>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item id="opt2" aria-role="listitem" :value="'changePassword'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
                      class="icons-1x icons-key" />
                    {{ translate("changePassword") }}</a>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="false" id="opt3" aria-role="listitem" :value="'changeLanguage'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
                      class="icons-1x icons-language" />
                    {{ translate("language") }}</a>
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item id="opt4" aria-role="listitem" :value="'showUserSettings'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
                      class="icons-1x icons-group" />
                    {{ translate("usersManagement") }}</a>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- <div v-if="(hasIniFile && isElectronVar && !this.isWindows)"> -->
      <div v-if="isElectronVar">
        <div style="text-align: end">
          <b-dropdown true id="dropdownSys" position="is-bottom-left" aria-role="list"
            v-model="selectedOptionSettingsDropdown" :title="translate('settings')">
            <template #trigger>
              <div class="topnav">
                <a id="btnControl" class="btnControl" @click.prevent="resetClassDiv()">
                  <i class="icons-white icons-1x icons-switch"></i>
                </a>
              </div>
            </template>

            <b-dropdown-item aria-role="listitem" v-if="false" value="'forcedLogOff'" id="optS1">
              <div class="media">
                <div class="media-content">
                  <i style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                    class="icons-1x icons-sign-out" />
                  {{ translate("btnLogoff") }}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item id="optS2" aria-role="listitem" v-if="false" :value="'logOff'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                      class="icons-1x icons-target" />
                    {{ translate("btnForcedLogOff") }}</a>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item id="optS3" aria-role="listitem" :value="'restart'">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                      class="icons-1x icons-sync" />
                    {{ translate("btnRestart") }}</a>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" :value="'shutdown'" id="optS4" v-if="isElectronVar && completeLogin">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                      class="icons-1x icons-switch" />
                    {{ translate("btnShutdown") }}</a>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" :value="'runOsAdmin'" id="optS5" v-if="verifyOsAdmin()">
              <div class="media">
                <div class="media-content">
                  <a href @click.prevent=""><i
                      style="color: var(--iconColor); margin-right: 5px; min-width: 25px; text-align: center;"
                      class="icons-1x icons-window" />
                    {{ translate("btnManageSystem") }}</a>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <CountryDropdown v-if="true" />
  </header>
</template>

<script>
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { machineOperation, loggedStatus } from "@/mixins/enums.js";
import { returnApp } from "@/js/resetApp.js";
import { closeWindow, getRoute } from "@/js/screenAdjustment.js";
import Message from "@/components/message/Message.vue";
import CountryDropdown from "./CountryDropdown";
import UserDropdown from "@/components/dropdown/UserDropdown.vue";
import UserSettings from "@/views/admin/Modules/UserPages/UserSettings.vue";
import User from "@/views/admin/Modules/User";
import Settings from "@/components/dropdown/Settings.vue";
import Language from "@/components/language/Language.vue";
import Auth from "../auth/Auth.vue";
import Token from "../auth/Token.vue";
import AddCash from "../cash/AddCash.vue";
import notify from "@/config/notify.js";
import LoadingCMD from "../processCMD/LoadingCMD";
import Logs from "@/components/logs/Logs.vue";
import SearchFilter from "@/components/dropdown/SearchFilter.vue";
import Configuration from "@/views/admin/Modules/Configurations.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";

import "@/css/icons.css";

export default {
  computed: mapState([
    "establishmentName",
    "communication",
    "footer",
    "dataModule",
    "blockMachine",
    "resetToken",
    "userLogged",
    "hasControl",
    "completeLogin",
    "hasDatabase",
    "errorS2S",
    "lang",
    "locale",
    "showHideBtns",
    "hasAddCash",
    "showHashCode",
    "envVars",
    "isElectronVar",
    "isWindows",
    "hasIniFile",
    "isFBM",
    "IP",
    "operatorName",
    "actualTimeZone"

  ]),
  name: "Header",
  mixins: [en, es, nor, swe],
  components: {
    CountryDropdown,
    Auth,
    Token,
    AddCash,
    LoadingCMD,
    UserDropdown,
    UserSettings,
    Language,
    Settings,
    Logs,
    Message,
    SearchFilter,
    User,
    Configuration
  },
  data() {
    return {
      showAuth: false,
      showAddCash: false,
      showToken: false,
      route: "",
      showClosing: false,
      countDown: 60,
      showLoadingCMD: false,
      showUser: false,
      sendingCMDMessage: "",
      isLoading: false,
      stopCountdown: false,
      firstCMDanwser: true,
      action: null,
      cmd: "",
      mode: "",
      fromLogOut: "",
      showChangeLanguage: false,
      showUserSettings: false,
      showConfig: false,
      penDriveList: [],
      showModalRestart: false,
      showModalLogs: false,
      message: "",
      logs: {},
      local: "",
      selectedOptionUserDropdown: "",
      selectedOptionSettingsDropdown: "",
      listOperator: [],
      operatorSelected: "",
      obj: {},
      intervalGetDatas: null,
      listOperatorFilter: [],
      IsFromHeader: true
    };
  },
  props: {},
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    processRestart() {
      if (this.completeLogin) {
        this.confirmRestart();
      } else {
        //refresh();
        this.sendServerRestart();
      }
    },
    afterToken() {
      if (this.blockMachine) {
        this.confirmRestart();
      } else {
        this.processConfirmBlockMachines();
        this.route = "/header/restart";
      }
    },
    confirmRestart() {
      this.$buefy.dialog.confirm({
        message: this.translate("massageRestartServer"),
        onConfirm: () => this.sendServerRestart(),
        onCancel: () => (this.route = ""),
      });
    },
    sendServerRestart() {
      this.$store.commit("changeStopRequest", true);
      this.sendCMD(
        clientCommands.ServerRestart.name,
        this.translate("waiting") + " " + this.translate("cmdRestartServer"),
        clientCommands.ServerRestart.time
      );
    },
    hideMenu() {
      this.sendCMD(
        clientCommands.HideMenu.name,
        this.translate("hideMenuMessage"),
        clientCommands.HideMenu.time
      );
    },
    processClose() {
      if (this.completeLogin) {
        this.$buefy.dialog.confirm({
          message: this.translate("messageCloseServer"),
          onConfirm: () => this.sendServerClose(),
        });
      } else {
        closeWindow();
      }
    },
    sendServerClose() {
      this.$store.commit("changeStopRequest", true);
      this.sendCMD(
        clientCommands.ServerStop.name,
        this.translate("waiting") + " " + this.translate("cmdServerStop"),
        clientCommands.ServerStop.time
      );
    },
    showInfo() {
      this.$buefy.dialog.alert({
        title: (this.isFBM ? "Teste " : "DINGO-Server ") + this.footer.version,
        message:
          "<b>- Bug Fixes</b><br/> ----------------------------- <br/> " +
          "<b>- What's New</b><br/> ----------------------------- <br/> ",
        type: "is-primary",
        hasIcon: true,

        icon: "info",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    showLogs() {
      this.showModalLogs = !this.showModalLogs;
    },
    processShowLogs() {
      this.cmd = clientCommands.ShowLogs.name;
      this.obj = {};
      this.countDown = clientCommands.ShowLogs.time;
      this.sendingCMDMessage =
        this.translate("waiting") + " " + this.translate("messageShowLogs");
      this.route = "";
      this.showLoadingCMD = true;
    },

    processShowConfig() {
      this.showConfig = !this.showConfig
    },

    processOpenAddCash() {
      try {
        if (this.showHashCode) {
          if (this.isElectronVar) {
            this.cmd = clientCommands.GetPenDriveList.name;
            this.obj = {};
            this.countDown = clientCommands.GetPenDriveList.time;
            this.sendingCMDMessage =
              this.translate("waiting") +
              " " +
              this.translate("messageRequestPenDriveList");
            this.route = "/header/AddCash";
            this.showLoadingCMD = true;
          } else {
            this.showAddCash = true;
          }
        } else {
          this.cmd = clientCommands.RequestHashCodeFile.name;
          this.obj = {};
          this.countDown = clientCommands.RequestHashCodeFile.time;
          this.sendingCMDMessage = this.translate("messageRequestHashCode");
          this.route = "/header/AddCash";
          this.showLoadingCMD = true;
        }
      } catch (e) {
        console.log("Error in openAddCash() " + e);
      }
    },
    openAddCash(data) {
      if (data.ok) {
        this.showAddCash = true;
        this.penDriveList = data.object.PenDriveInfoList;
      }
    },
    btnControl() {
      if (!this.hasControl) {
        this.showAuth = true;
        this.route = "/api/configuration/controls";
      } else {
        this.fromLogOut = true;
        this.route = "/header/communication/block/fromUser";
        if (!this.communication) {
          //this.processConfirmCommunication();
          this.sendCMD(
            clientCommands.UnblockCommunication.name,
            this.translate("waiting") +
            " " +
            this.translate("cmdUnblockCommunication"),
            clientCommands.UnblockCommunication.time
          );
        } else {
          this.logOutUser();
        }
      }
    },
    logOutUser() {
      this.$buefy.dialog.confirm({
        message: this.translate("messageSignOut"),
        onConfirm: () => this.sendLogout(),
      });
    },
    restart() {
      returnApp(this);
    },
    signOut() {
      try {
        notify.message(
          "foo",
          "Success",
          "user [" + this.userLogged.Login + " logged out]",
          "success",
          2000
        );
        //refresh();
        this.$store.commit("changehasControl", false);
        this.$store.commit("setUserLogged", {});
        this.$store.commit("changeCompleteLogin", false);
        this.$store.commit("setUserLoggedTemp", {});
        localStorage.removeItem("ManagerOptions" + '/' + this.IP);
        localStorage.removeItem("CashierOptions" + '/' + this.IP);
        localStorage.removeItem("UserLoggedTemp" + '/' + this.IP)
        this.$store.commit("changeIP", "");
        //TQV
        localStorage.setItem("Token" + this.IP , "")
        this.restart();
      } catch (e) {
        console.log("Error in SignOut " + e);
      }
    },
    sendLogout() {
      try {
        this.cmd = clientCommands.LoggedOut.name;
        this.userLogged.Status = loggedStatus.NOT_LOGGED;
        this.obj = this.userLogged;
        this.countDown = clientCommands.LoggedOut.time;
        this.sendingCMDMessage =
          this.translate("waiting") + " " + this.translate("sign_Out");
        this.showLoadingCMD = true;
      } catch (e) {
        console.log("Error in sendCmdLogout() " + e);
      }
    },
    processConfirmCommunication() {
      if (!this.communication) {
        this.$buefy.dialog.confirm({
          title: this.translate("dialog_Title"),
          message:
            this.translate("messageUnblockCommunication") +
            " <br/> " +
            this.translate("messageAllMachinesConnect"),
          confirmText: "Confirm",
          type: "is-primary",
          hasIcon: true,
          onConfirm: () =>
            this.sendCMD(
              clientCommands.UnblockCommunication.name,
              this.translate("waiting") +
              " " +
              this.translate("cmdUnblockCommunication"),
              clientCommands.UnblockCommunication.time
            ),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: this.translate("dialog_Title"),
          message:
            this.translate("messageBlockCommunication") +
            " <br/> " +
            this.translate("messageAllMachinesDisconnect"),
          confirmText: "Confirm",
          type: "is-primary",
          hasIcon: true,
          onConfirm: () =>
            this.sendCMD(
              clientCommands.BlockCommunication.name,
              this.translate("waiting") +
              " " +
              this.translate("cmdBlockCommunication"),
              clientCommands.BlockCommunication.time
            ),
        });
      }
    },
    afterSettings(action) {
      try {
        switch (action) {
          /*case "logOff":
            type = machineOperation.LogOff;
            message = this.translate("btnLogoff");
            break;
          case "forcedLogOff":
            type = machineOperation.ForcedLogOff;
            message = this.translate("btnForcedLogOff");
            break;*/
          case "restart":
            /*type = machineOperation.Restart;
            message = this.translate("btnRestart");*/
            this.processShowAuth("Header/Restart");
            break;
          case "shutdown":
            /*type = machineOperation.Shutdown;
            message = this.translate("btnShutdown");
            */
            this.processShowAuth("Header/Shutdown");
            break;
          case "runOsAdmin":
            this.processShowAuth("Header/RunOsAdmin");
            break;
          default:
            console.log("action not found from Settings: " + action);
        }
      } catch (e) {
        console.log("Error in afterSettings " + e);
      }
    },
    processShowAuth(local) {
      //Pedir Senhar apenas se tiver com banco
      this.local = local;
      //if (this.hasControl && this.hasDatabase) {
      if (this.hasDatabase) {
        this.showAuth = true;
      } else {
        this.afterAuth(local);
      }
    },
    afterAuth(local) {
      let type = 0xff;
      let message = "";
      switch (local) {
        case "Header/RunOsAdmin":
          this.obj = {};
          this.sendCMD(
            clientCommands.RunOsAdmin.name,
            this.translate("messageRunOsAdmin"),
            clientCommands.RunOsAdmin.time
          );
          break;
        case "Header/Shutdown":
          type = machineOperation.Shutdown;
          message = this.translate("btnShutdown");
          this.obj = {
            Value: type,
          };
          this.sendCMD(
            clientCommands.MachineOperation.name,
            this.translate("waiting") + " " + message,
            clientCommands.MachineOperation.time
          );
          break;
        case "Header/Restart":
          type = machineOperation.Restart;
          message = this.translate("btnRestart");
          this.obj = {
            Value: type,
          };
          this.sendCMD(
            clientCommands.MachineOperation.name,
            this.translate("waiting") + " " + message,
            clientCommands.MachineOperation.time
          );
          break;
        default:
          console.log("afterAuth Unknown " + local);
          break;
      }
    },
    actionAfterMachineOperation(data) {
      switch (data.cmd) {
        case clientCommands.MachineOperation.name:
          this.showModalRestart = true;
          this.message = data.message;
          this.countDown = data.timeToRestart;
          this.finishConfig(data, true);
          break;
        default:
          break;
      }
    },
    actionAfterShowLogs(data) {
      try {
        switch (data.cmd) {
          case clientCommands.ShowLogs.name:
            if (data.object != null) {
              this.logs = data.object;
            }
            this.showLogs();
            break;
          default:
            break;
        }
      } catch (e) {
        console.log("Error in actionAfterShowLogs Header() " + e);
      }
    },
    finishConfig: function (obj, forceRestart) {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown <= 0) {
            if (obj.restartRequired) {
              console.log("Restart after Settings: " + obj.cmd);
              this.restart();
              this.showModalRestart = false;
            }
          } else {
            this.finishConfig(obj, forceRestart);
          }
        }, 1000);
      }
    },
    sendCMD(cmd, message, countDown) {
      this.cmd = cmd;
      this.sendingCMDMessage = message;
      this.showLoadingCMD = true;
      this.countDown = countDown;
    },
    verifyUserLogged() {
      try {
        if (this.userLogged.Technician || this.userLogged.Root) {
          return true;
        }
      } catch (e) {
        console.log("verifyUserLogged" + e);
        return false;
      }
      return false;
    },
    actionFromUserDrop(action) {
      switch (action) {
        case "signOut":
          this.logOutUser();
          this.selectedOptionUserDropdown = "";
          break;
        case "changePassword":
          this.showUser = true;
          this.selectedOptionUserDropdown = "";
          this.mode = "edit";
          break;
        case "changeLanguage":
          this.showChangeLanguage = true;
          this.selectedOptionUserDropdown = "";
          break;
        case "showUserSettings":
          this.showUserSettings = true;
          this.selectedOptionUserDropdown = "";
          break;
      }
    },
    actionAfterSelectOperator(data) {
      if (data.ok) {
        this.$store.commit("changeOperatorName", this.opName);
      }
    },
    returnBtnAccountMessage(hasControl) {
      if (hasControl) {
        return "Click to Sign out [" + this.userLogged.Login + "]";
      } else {
        return "Click to Sign in";
      }
    },
    showSettings() {
      return "Login".toLowerCase() != getRoute(this).toLowerCase();
    },

    verifyOsAdmin() {
      let ret = false;
      if (this.isElectronVar && !this.isWindows) {

        ret = true;

      }
      return ret;
    },

    updateOperatorName(name) {
      this.cmd = clientCommands.SelectOperator.name;
      this.obj = {
        Value: name
      };
      this.countDown = clientCommands.SelectOperator.time;
      this.sendingCMDMessage =
        this.translate("waiting") + " " + this.translate("messageGetOperatorServer");
      this.route = "/modules/header";
      this.opName = name;
      this.showLoadingCMD = true;
      this.showSelectOperator = false;
    },

    resetClassDiv() {
      const opt1 = document.getElementById("opt1");
      if (opt1 != undefined && opt1.className == "dropdown-item is-active") {
        opt1.classList.remove("is-active");
      }
      const opt2 = document.getElementById("opt2");
      if (opt2 != undefined && opt2.className == "dropdown-item is-active") {
        opt2.classList.remove("is-active");
      }
      const opt3 = document.getElementById("opt3");
      if (opt3 != undefined && opt3.className == "dropdown-item is-active") {
        opt3.classList.remove("is-active");
      }

      const opt4 = document.getElementById("opt4");
      if (opt4 != undefined && opt4.className == "dropdown-item is-active") {
        opt4.classList.remove("is-active");
      }

      const optS1 = document.getElementById("optS1");
      if (optS1 != undefined && optS1.className == "dropdown-item is-active") {
        optS1.classList.remove("is-active");
      }

      const optS2 = document.getElementById("optS2");
      if (optS2 != undefined && optS2.className == "dropdown-item is-active") {
        optS2.classList.remove("is-active");
      }

      const optS3 = document.getElementById("optS3");
      if (optS3 != undefined && optS3.className == "dropdown-item is-active") {
        optS3.classList.remove("is-active");
      }

      const optS4 = document.getElementById("optS4");
      if (optS4 != undefined && optS4.className == "dropdown-item is-active") {
        optS4.classList.remove("is-active");
      }

      const optS5 = document.getElementById("optS5");
      if (optS5 != undefined && optS5.className == "dropdown-item is-active") {
        optS5.classList.remove("is-active");
      }
    },

    refreshPenDriveFilesAddCash() {
      this.processOpenAddCash()
    },

    refreshResetPassword() {
      this.$store.commit("changeShowHashCode", true);
      setTimeout(() => this.showAddCash = true, 300);
    },

    getDatas() {
      this.intervalGetDatas = setInterval(() => {
        //console.log("/getDatas()Dash");
        try {
          this.listOperatorFilter = []
          if (this.dataModule.Operator != null) {
            this.dataModule.Operator.forEach(op => {
              const opFilter = {
                name: op
              }
              this.listOperatorFilter.push(opFilter)
            });
          }
        } catch (e) {
          notify.message("foo", "Error", e.message, "error", 2000);
        }
      }, 500);
    },


    processSettings(value) {
      let confirMessage = "";
      switch (value) {
        case "logOff":
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: this.translate("messageProcessLogOff"),
            confirmText: this.translate("btnOk"),
            cancelText: this.translate("btnCancel"),
            type: "is-primary",
            hasIcon: true,
            onConfirm: () => this.afterSettings(value),
          });
          break;
        case "forcedLogOff":
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: this.translate("messageProcessForcedLogOff"),
            confirmText: this.translate("btnOk"),
            cancelText: this.translate("btnCancel"),
            type: "is-primary",
            hasIcon: true,
            onConfirm: () => this.afterSettings(value),
          });
          break;
        case "restart":
          confirMessage = this.hasEmailToSend
            ? this.translate("messageProcessRestart") +
            " <br/> <br/> " +
            "<b>" +
            this.translate("messageHasEmailToSend")
            : this.translate("messageProcessRestart") + "</b>";
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: confirMessage,
            confirmText: this.translate("btnOk"),
            cancelText: this.translate("btnCancel"),
            type: "is-primary",
            hasIcon: true,
            onConfirm: () => this.afterSettings(value),
          });
          break;
        case "shutdown":
          confirMessage = this.hasEmailToSend
            ? this.translate("messageProcessShutdown") +
            " <br/> <br/> " +
            "<b>" +
            this.translate("messageHasEmailToSend")
            : this.translate("messageProcessShutdown") + "</b>";
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: confirMessage,
            confirmText: this.translate("btnOk"),
            cancelText: this.translate("btnCancel"),
            type: "is-primary",
            hasIcon: true,
            onConfirm: () => this.afterSettings(value),
          });
          break;
        case "runOsAdmin":
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: this.translate("messageTurnOffServer"),
            confirmText: "Confirm",
            type: "is-primary",
            hasIcon: true,
            onConfirm: () => this.afterSettings(value),
          });
          break;
      }
    },
  },
  beforeDestroy() {
    console.log("/closed Header");
    clearInterval(this.intervalGetDatas);
  },
  mounted() {
    clearInterval(this.intervalGetDatas);
    this.getDatas();
  },
  watch: {
    selectedOptionUserDropdown(value) {
      this.actionFromUserDrop(value);
      this.selectedOptionUserDropdown = "";
    },
    selectedOptionSettingsDropdown(value) {
      if (this.completeLogin) {
        this.processSettings(value);
        if (value != "")
          this.selectedOptionSettingsDropdown = "";
      } else {
        if (value != "")
          this.afterSettings(value)
        this.selectedOptionSettingsDropdown = "";
      }
    },
  },
};
</script>

<style scoped src="@/css/header.css"></style>
