import Vue from "vue";

export const userKey = "access_token";
export const baseApiUrl = "http://localhost:5000";
export const passwordFront = "1966";
export const passwordLoading = "2852";
export const colorFBM = "#d83d3d";
export const colorDINGO = "#458DD6";
export const colorGlobal = "#ffb648";

export function showError(e) {
  if (e && e.response && e.response.data) {
    Vue.toasted.global.defaultError({ msg: e.response.data });
  } else if (typeof e === "string") {
    Vue.toasted.global.defaultError({ msg: e });
  } else {
    Vue.toasted.global.defaultError();
  }
}

export default {
  baseApiUrl,
  showError,
  userKey,
  passwordFront,
  passwordLoading,
  colorFBM,
  colorDINGO,
};
