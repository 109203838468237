<template>
    <div class="client">
        <div class="container-fluid">
            <div class="animated fadeIn">
                <div class="card">
                    <div class="card-body">
                        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
                            aria-modal :can-cancel="false">
                            <template #default="props">
                                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                                    :countDown="countDown" @ActionAfterClientOptions="afterClientOptions" :route="route">
                                </LoadingCMD>
                            </template>
                        </b-modal>
                        <b-field grouped>
                            <div class="col-lg-8 searchTerminal">
                                <b-input :placeholder="translate('lblSearchByName')" expanded type="search" icon="magnify"
                                    @icon-right-click="searchByName = ''" icon-right="close-circle" icon-right-clickable
                                    id="lblSearchByName" v-model="searchByName" />
                            </div>
                            <div class="col-lg-2">
                                <b-select v-if="false" v-model="searchByConnected" :placeholder="translate('columnStatus')">
                                    <option v-for="option in clientStatus" :value="option.value" :key="option.value">
                                        {{ translate(option.name) }}
                                    </option>
                                </b-select>
                                <b-dropdown aria-role="list" scrollable max-height="200" trap-focus v-model="searchByConnected">
                                    <template #trigger>
                                    <a class="navbar-item" role="button">
                                        <span v-if="searchByConnected.name == '' || searchByConnected.name == 'All'">
                                            {{ translate("lblSearchStatus") }}
                                        </span>
                                        <span v-else>
                                            {{ translate("columnStatus") }}: {{ translate(searchByConnected.name) }}
                                        </span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </a>
                                    </template>
                                    <b-dropdown-item v-for="option in clientStatus" :value="option" :key="option">
                                        <span>{{ translate(option.name) }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>

                            

                            <div class="col-lg-2">
                                <b-select v-if="false" v-model="searchByServer" :placeholder="translate('columnServer')">
                                    <option v-for="option in clientStatus" :value="option.value" :key="option.value">
                                        {{ translate(option.name) }}
                                    </option>
                                </b-select>
                                <b-dropdown aria-role="list" scrollable max-height="200" trap-focus v-model="searchByServer">
                                    <template #trigger>
                                    <a class="navbar-item" role="button">
                                        <span v-if="searchByServer.name == '' || searchByServer.name == 'All'">
                                            {{ translate("lblSearchByServer") }}                                            
                                        </span>
                                        <span v-else>
                                            {{ translate("columnServer") }}: {{ searchByServer.name }}
                                        </span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </a>
                                    </template>
                                    <b-dropdown-item v-for="option in listClients" :value="option" :key="option">
                                        <span>{{ option.name }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            
                        </b-field>
                        <div style="overflow-y: auto; height: 100% !important">
                            <div class="terminal-tab" style="
                                                  overflow-y: auto;
                                                  overflow-x: hidden;
                                                  padding-left: 14px;
                                                  font-size: 5px !important;
                                                ">
                                &nbsp;
                                <span>
                                    {{ translate("activeClients") }} [{{ activeClient }}]
                                    | {{ translate("inactiveClients") }} [{{ inactiveClient }}]
                                    | {{ translate("blockedClients") }} [{{ blockedClient }}]
                                    | {{ translate("deletedClients") }} [{{ deletedClient }}]
                                    | {{ translate("InFilter") }} [{{ inFilter }}]
                                </span>
                                <br />
                                <br />                               
                                <button class="stat-terminal" v-for="client in clients" :key="client.Name"
                                    :title="client.Name" data-toggle="tooltip" data-placement="top"
                                    style="min-width: 150px; min-height: 150px; margin-bottom: 10px; " disabled
                                    @click.prevent="processShowClient(client)" v-show="verifyNameClient(client)">
                                    <div class="stat-icon">
                                        <div class="row" style="">
                                            <div style="padding-left: 45px !important; padding-bottom: 10px !important;">
                                                <p class="icons-lg icons-server-config" :style="getStyle(client)" />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div style="padding-bottom: 40px !important; width: 20px !important;">
                                                <b-button size="is-small" style="border-color: white; position: static"
                                                @mouseenter="enableShowInfoTerminal = false" @mouseleave="enableShowInfoTerminal = true"
                                                @click.prevent="
                                                    showClientOptionsModal(client)
                                                    " :title="translate('messageBtnShowTerminalOptions')
                                                    " icon-left=".icons-white icons-dots-three-vertical" icon-pack="icons" />
                                            </div>
                                            &nbsp;
                                        </div>
                                        <span class="stat-title-terminal" style="font-size: 9px !important">
                                            {{ client.Name.slice(0, 24) + (client.Name.length > 24 ? "..." : "") }}
                                            <br />
                                            {{ client.ClientType == 2 ? "System Server" : "Sirius Server" }}
                                            <br />
                                        </span>                                        
                                        <span class="stat-title-terminal" style="font-size: 9px !important">
                                            <i class="icons-lg icons-calendar" style="color: #777777"></i>&nbsp;{{ client.DateUpdate }} (UTC)
                                        </span>
                                    </div>
                                    <br />
                                </button>
                            </div>
                            <div :class="'col-lg-' + (12 - 2 * ((clients.length + 1) % 4))" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="showClientOptions" width="600px" trap-focus :destroy-on-hide="true" :can-cancel="false"
            aria-role="dialog" aria-modal>
            <template>
                <div class="modal-card" style="width: 100%; height: 450px">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ translate("messageBtnShowClientOptions") }} -
                        {{ selectedClient.Name }}
                    </p>
                    <button type="button" class="delete" @click="showClientOptions = false" />
                </header>
                <section class="modal-card-body">
                    <b-modal v-model="showLoadingClientOptionsCMD" width="auto" trap-focus :destroy-on-hide="true"
                        aria-role="dialog" aria-modal :can-cancel="false">
                        <template #default="props">
                            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
                                @ActionAfterClientOptions="afterClientOptions" :JsonObject="obj">
                            </LoadingCMD>
                        </template>
                    </b-modal>
                    <b-dropdown v-model="selectClientOption" aria-role="list" expanded>
                        <template v-if="true" #trigger>
                            <b-button v-if="selectClientOption == clientOptions.block" expanded
                                :label="translate('btnBlockClient')" icon-left=".icons-white icons-server-config"
                                icon-pack="icons" />
                            <b-button v-if="selectClientOption == clientOptions.unblock" expanded
                                :label="translate('btnUnblockClient')" icon-left=".icons-white icons-server-config"
                                icon-pack="icons" />
                            <b-button v-if="selectClientOption == clientOptions.delete" expanded
                                :label="translate('btnDeleteClient')" icon-left=".icons-white icons-trash"
                                icon-pack="icons" />
                                <b-button v-if="selectClientOption == clientOptions.recover" expanded
                                :label="translate('btnRecoverClient')" icon-left=".icons-white icons-trash-arrow-up-solid"
                                icon-pack="icons" />
                        </template>

                        <b-dropdown-item :value="clientOptions.block" aria-role="listitem"
                            v-if="!selectedClient.Blocked && !selectedClient.Deleted">
                            <span><i class="icons-server-config" />
                                {{ translate("btnBlockClient") }}</span>
                        </b-dropdown-item>
                        
                        <b-dropdown-item :value="clientOptions.unblock" aria-role="listitem"
                            v-if="selectedClient.Blocked && !selectedClient.Deleted">
                            <span><i class="icons icons-server-config" />
                                {{ translate("btnUnblockClient") }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item :value="clientOptions.recover" aria-role="listitem"
                            v-if="selectedClient.Deleted">
                            <span><i class="icons icons-trash-arrow-up-solid" />
                                {{ translate("btnRecoverClient") }}</span>
                        </b-dropdown-item>

                        <b-dropdown-item :value="clientOptions.delete" aria-role="listitem"
                            v-if="!selectedClient.Deleted && !selectedClient.Connected && selectedClient.Blocked">
                            <span><i class="icons icons-trash" />
                                {{ translate("btnDeleteClient") }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </section>
                <footer class="modal-card-foot">
                    <b-button @click="showClientOptions = false" :label="translate('btnCancel')"
                    icon-left=".icons-white icons-times" icon-pack="icons" />
                    <b-button @click="processSendOption()" :label="translate('btnOk')" type="is-primary"
                    :icon-left="'.icons-white icons-check'" icon-pack="icons" />
                </footer>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { clientStatus, clientOptions, listClients } from "@/mixins/enums.js";
import { clientCommands } from "@/js/ClientCommands.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
export default {
    name: "Client",
    mixins: [en, es, nor, swe],
    components: { LoadingCMD },
    computed: mapState([
        "lang",
        "dataModule",
        "currentTab",
        "completeLogin"
    ]),

    data: function () {
        return {
            name: "",
            showLoadingCMD: false,
            cmd: "",
            countDown: 60,
            route: "",
            intervalGetDatas: null,
            showClientModal: false,
            searchByName: "",
            searchByConnected: { value: 0, name: "" },
            searchByServer:  { value: 0, name: "" },
            clientStatus: clientStatus,
            listClients: listClients,
            clients: [],
            activeClient: 0,
            inactiveClient: 0,
            blockedClient: 0,
            deletedClient:0,
            inFilter: 0,
            showLoadingClientOptionsCMD: false,
            clientOptions: clientOptions,
            showClientOptions: false,
            selectedClient: {},
            selectClientOption: 0,
        }
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        getStyle(client) {
            if (client.Deleted) {
                return "color: #FF8888; font-size: xxx-large;";
            } else if (client.Blocked) {
                return "color: #EE3333; font-size: xxx-large;";
            } else if (client.Connected) {
                return "color: #339933; font-size: xxx-large;";
            } else {
                return "color: #CCCCCC; font-size: xxx-large;";
            }                   
        },
        getDatas() {
            try {
                this.intervalGetDatas = setInterval(() => {
                    if (
                        this.currentTab != "Client" ||
                        !this.completeLogin
                    ) {
                        console.log("/closed getDatas Client()");
                        clearInterval(this.intervalGetDatas);
                    }
                    this.clients = this.dataModule.Clients
                    let activeClient = 0;
                    let inactiveClient = 0;
                    let blockedClient = 0;
                    let deletedClient = 0;
                    let inFilter = 0;
                    this.clients.forEach((client) => {
                        if (client.Connected) {
                            activeClient = activeClient + 1;
                        }else if (client.Deleted) {
                            deletedClient = deletedClient + 1;
                        } else if (client.Blocked) {
                            blockedClient = blockedClient + 1;
                        } else {
                            inactiveClient = inactiveClient + 1;
                        }
                        if (this.verifyNameClient(client)) {
                            inFilter = inFilter + 1;
                            console.log("/mountedDataClient()");
                        }
                    });
                    this.activeClient = activeClient;
                    this.inactiveClient = inactiveClient;
                    this.blockedClient = blockedClient;
                    this.deletedClient = deletedClient;
                    this.inFilter = inFilter;
                }, 1000);
            } catch (e) {
                console.log("Error in GetDatas() Client " + e);
            }
        },
        verifyNameClient(client) {
            let ret = false;            
            if ((client.Name.toLowerCase()).includes(this.searchByName.toLowerCase())) {
                 if (this.searchByConnected.value == 0 && client.Connected && (this.searchByServer.value == 0 || this.searchByServer.value == client.ClientType)) {
                    ret = true;
                } else if (this.searchByConnected.value == 1 && !client.Connected && !client.Blocked && !client.Deleted && (this.searchByServer.value == 0 || this.searchByServer.value == client.ClientType)) {
                    ret = true;
                } else if (this.searchByConnected.value == 2 && client.Blocked && !client.Deleted && (this.searchByServer.value == 0 || this.searchByServer.value == client.ClientType)) {
                    ret = true;
                }else if (this.searchByConnected.value == 3 && client.Deleted && (this.searchByServer.value == 0 || this.searchByServer.value == client.ClientType)) {
                    ret = true;
                }else if (this.searchByConnected.value == 4 && (this.searchByServer.value == 0 || this.searchByServer.value == client.ClientType)) {
                    ret = true;
                }
            }
            return ret;
        },
        showClientOptionsModal(client) {
            this.showClientOptions = true;
            this.selectedClient = client;

            if (!client.Blocked && !client.Deleted) {
                this.selectClientOption = clientOptions.block;
            } else if(client.Blocked && !client.Deleted){
                this.selectClientOption = clientOptions.unblock;
            }else if(client.Deleted){
                this.selectClientOption = clientOptions.recover;
            }else{
                this.selectClientOption = clientOptions.delete;
            }
           
        },
        processSendOption() {
            if (this.selectClientOption == clientOptions.block) { // Block Client
                this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message:
                    this.translate("msgBlockClientConfirmation") +
                    " <br/> " +
                    this.selectedClient.Name,
                confirmText: this.translate("btnYes"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => this.sendOption(this.selectClientOption),
                });
            } else if (this.selectClientOption == clientOptions.unblock) { // Unblock Client
                this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message:
                    this.translate("msgUnblockClientConfirmation") +
                    " <br/> " +
                    this.selectedClient.Name,
                confirmText: this.translate("btnYes"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => this.sendOption(this.selectClientOption),
                });
            }
            else if (this.selectClientOption == clientOptions.delete) { // Delete Client
                this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message:
                    this.translate("msgDeleteClientConfirmation") +
                    " <br/> " +
                    this.selectedClient.Name,
                confirmText: this.translate("btnYes"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => this.sendOption(this.selectClientOption),
                });
            }
            else if (this.selectClientOption == clientOptions.recover) { // Recover Client
                this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message:
                    this.translate("msgRecoverClientConfirmation") +
                    " <br/> " +
                    this.selectedClient.Name,
                confirmText: this.translate("btnYes"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => this.sendOption(this.selectClientOption),
                });
            }
        },
        sendOption(selectedOption) {
            try {
                this.cmd = clientCommands.ClientOptions.name;
                this.countDown = clientCommands.ClientOptions.time;
                this.sendingCMDMessage = this.translate("lblLoading");
                this.obj = {
                    Client: this.selectedClient,
                    Option: selectedOption,
                };
                this.showLoadingClientOptionsCMD = true;
            } catch (e) {
                console.log("Error in sendGroupMachinesOption()" + e);
            }
        },
        afterClientOptions() {
            this.showClientOptions = false;
        },
    },
    created() {
        this.clients = this.dataModule.Clients
        if (this.currentTab == "Client")
            this.getDatas();
    },
    mounted() {
        this.searchByConnected = clientStatus[0]        
        this.searchByServer = listClients[0]
    },
    beforeDestroy() {
        console.log("/closed getDatas()");
        clearInterval(this.intervalGetDatas);
    },
}
</script>
<style scoped src="@/css/styleModules.css"></style>
